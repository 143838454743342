import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { forkJoin } from "rxjs";

import { environment } from "./../../environments/environment";

import { RegionalPlain } from "../models/regionalPlain.model";

import { HttpUtilService } from "./../shared/services/http-util.service";

@Injectable({
  providedIn: "root"
})
export class RegionalPlainService {
  private API_URL = `${environment.API_URL}regionalPlain`;  
  private API_UPLOAD_IMG = `${environment.API_UPLOAD_EXCEL}uploads`;

  constructor(private http: Http, private httpUtil: HttpUtilService) {}

  fileUploadRegionalForms(img: any, pdf: any): Observable<any[]> {
    const imgForm: FormData = new FormData();
   img ? imgForm.append("formData", img, img.name) : img ='';

    const pdfForm: FormData = new FormData();
    pdf ? pdfForm.append("formData", pdf, pdf.name) : pdf = '';

    const url1 = this.http.post(this.API_UPLOAD_IMG, imgForm);
    const url2 = this.http.post(this.API_UPLOAD_IMG, pdfForm);

    return forkJoin([url1, url2]);
  }

  getPlanoRegional() {
    return this.http
      .get(this.API_URL)
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  getPlanoRegionalById(id: string) {
    return this.http
      .get(`${this.API_URL}/${id}`)
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  postPlanoRegional(form: RegionalPlain) {
    return this.http
      .post(this.API_URL, form, this.httpUtil.headers())
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  updatePlanoRegional(id: string, form: RegionalPlain) {
    return this.http
      .put(`${this.API_URL}/${id}`, form, this.httpUtil.headers())
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  uploadImage(image: File) {
    const formData: FormData = new FormData();
    formData.append("formData", image, image.name);
    return this.http.post(this.httpUtil.urlUploadGeneric('uploads'), formData);
  }

  uploadPdf(pdf: File) {
    const formData: FormData = new FormData();
    formData.append("formData", pdf, pdf.name);
    return this.http.post(this.httpUtil.urlUploadGeneric('uploads'), formData);
  }

  deletePlanoRegional(id: string) {
    return this.http
      .delete(`${this.API_URL}/${id}`)
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }
}
