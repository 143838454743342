import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  ViewChild,
  ElementRef
} from "@angular/core";

import { RegionalPlainService } from "src/app/services/regional-plain.service";
import { AlertService } from "src/app/shared/services/alert.service";

import { RegionalPlain } from "src/app/models/regionalPlain.model";
import { Editoria } from "src/app/models/enum/editoria";

@Component({
  selector: "app-planos-list",
  templateUrl: "./planos-list.component.html",
  styleUrls: ["./planos-list.component.scss"]
})
export class PlanosListComponent implements OnInit, OnChanges {
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();

  @Input() reload: string;
  @Input() planos: RegionalPlain[] = [];
  @Input() spin: boolean;
  @Input() ad: boolean = false;

  public editoria = Editoria;
  public filtro: any = { title: "" };

  public disabledSwitch: boolean = false;

  constructor(
    private regionaPlainService: RegionalPlainService,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  ngOnChanges() {}

  updatePlanosRegionais(id: string) {
    this.update.emit(id);
  }

  changeAd(plano: RegionalPlain) {
    this.disabledSwitch = true;
    if (plano.status === 1) {
      plano.status = 2;      
      this.regionaPlainService.updatePlanoRegional(plano._id, plano).subscribe(
        data => {
          this.pbSucess();
        },
        () => {
          this.alertService.error(
            "Erro",
            "Ocorreu um erro ao inativar o Plano",
            "OK"
          );
          this.disabledSwitch = false;
        },
        () => {
          this.disabledSwitch = false;
        }
      );
    } else {
      plano.status = 1;    
      this.regionaPlainService.updatePlanoRegional(plano._id, plano).subscribe(
        data => {
          this.pbSucess();
        },
        () => {
          this.alertService.error(
            "Erro",
            "Ocorreu um erro ao ativar o Plano",
            "OK"
          );
          this.disabledSwitch = false;
        },
        () => {
          this.disabledSwitch = false;
        }
      );
    }
  }

  deletePlanosRegionais(id: string, title: string, plano: RegionalPlain) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${title}?`, "OK", "Não")
      .then(willDelete => {
        if (willDelete.value) {
          plano.status = 3;      
          this.regionaPlainService.updatePlanoRegional(id, plano).subscribe(() => {
            this.alertService.success("", "Título deletado com sucesso", "OK");
            this.delete.emit("Deletado");
          });
        }
      });
  }

  pbSucess() {
    this.disabledSwitch = false;
    this.delete.emit("Deletado");
    return this.alertService.success("", "Plano atualizado com sucesso", "OK");
  }
}
