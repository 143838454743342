export class Home {
  public _id: number;
  public editoria: string;
  public title: string;
  public link_banner: string;
  public description: string;
  public url_image_banner: string;
  public dateCreated: Date;
  public dateUpdated: Date;
}
