import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpUtilService } from '../shared/services/http-util.service';
import { Observable } from 'rxjs';
import { Emissora } from '../models/emissoras';

@Injectable({
  providedIn: 'root'
})
export class EmissorasService {


  private path = "emissoras";

  constructor(private http: Http, private httpUtil: HttpUtilService) { }

  getById(_id): Observable<Emissora> {
    return this.http
      .get(this.httpUtil.url(this.path + "/" + _id), this.httpUtil.headers())
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  getAll(): Observable<Emissora[]> {
    return this.http
      .get(this.httpUtil.url(this.path), this.httpUtil.headers())
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }
}
