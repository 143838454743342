import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public tutorial: string = 'https://imagem.band.com.br/novahome/comercial/f53dfce8-cc04-4214-a221-317b7fc29e41.pdf'

  constructor() { }

  ngOnInit() {
  }

}
