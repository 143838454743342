import { Editoria } from "./enum/editoria";
import { MerchanPlain } from "./merchanPlain";
import { SpecialAction } from "./specialAction";

export class PriceTable {

    public _id?: string;
    public editoria?: Editoria;
    public title?: string;
    public url_movie?: string;
    public promo?: string;
    public description?: string;
    public order?: number;
    public emissoras: Array<string> = [];

    public dateCreated?: Date;
    public dateUpdated?: Date;

    public url_image_destaque?: string;
    public url_file_comercialPlain?: string;

    public url_file_excel?: string;
    public url_file_excel_rede?: string;
    public url_file_ppt?: string;

    public comercialFormatMerchan: MerchanPlain[];
    public specialAction: SpecialAction[];


    public sunday?: boolean;
    public monday?: boolean;
    public tuesday?: boolean;
    public wednesday?: boolean;
    public thursday?: boolean;
    public friday?: boolean;
    public saturday?: boolean;
}
