export class AcoesDestaque {
  constructor(
    public _id :string = "",
    public programName: string = "",
    public programBrand: number = null,
    public description: string = "",
    public category?: number,    
    public url_video: string = "",    
    public month: number = null,
    public dateCreated?: Date,
    public dateUpdated?: Date
  ) {}
}
