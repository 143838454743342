import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // console.log(localStorage["token"], "token");
    if (localStorage["token"] != null) {
      if(localStorage["role"] === 'APP_PORTAL_PAYTV' || localStorage["role"] === 'App_Portal_Admin'){
        return true;
      } else {
        this.router.navigate(["/login"]);
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
