export class RegionalPlain{
    constructor(
        public _id: string = '',
        public title: string = '',
        public category: number  = null,
        public url_imagem: string = '',
        public url_movie: string = '',
        public state: number  = null,
        public emissora_name: string = '',
        public url_file_comercialPlain: string = '',
        public description: string = '',
        public order: number = null,
        public status: number = null
    ){}
}