import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { ComercialPlainService } from "src/app/services/comercial-plain.service";
import { ComercialPlain } from "src/app/models/comercialPlain";
import { Editoria } from "src/app/models/enum/editoria";
import { AlertService } from "src/app/shared/services/alert.service";
import { Router } from "@angular/router";
import { MerchanPlain } from "src/app/models/merchanPlain";
import { take } from "rxjs/operators";
import { EmissorasService } from "src/app/services/emissoras.service";
import { Emissora } from "src/app/models/emissoras";
import { SpecialAction } from "src/app/models/specialAction";

@Component({
  selector: "app-planos",
  templateUrl: "./planos.component.html",
  styleUrls: ["./planos.component.scss"]
})
export class PlanosComponent implements OnInit {
  _editoriaTipo = Editoria;
  obj: any;
  _id: any;
  key: any;
  selectedValue: Editoria.Arte1;
  selectedFile = null;
  public comercialPlain: ComercialPlain[];
  public comercialP: ComercialPlain;
  public editorias: Editoria;
  public total: number;
  public merchanPlain: Array<MerchanPlain> = [];
  public specialAction: Array<SpecialAction> = [];
  public merchanAdd: MerchanPlain;
  public specialActionAdd: SpecialAction;
  public urlNull: string = 'https://imagem.band.com.br/novahome/comercial/'

  public spinTotal: boolean = false;
  public spin: boolean = true;
  public spinPdf: boolean = true;
  public spinExcel: boolean = true;
  public spinExcelRede: boolean = true;
  public spinPpt: boolean = true;

  public image_destaque?: FileList;
  public file_comercialPlain?: FileList;

  public url_file_excel_temp?: FileList;
  public url_file_excel_rede_temp?: FileList;
  public url_file_ppt_temp?: FileList;

  public validaTitle: boolean = false;
  public validaUrlVideo: boolean = false;
  public validaDiasSemana: boolean = false;

  public validaImgDestaque: boolean = false;
  public validaPlano: boolean = false;
  public validaExcel: boolean = false;
  public validaExcelRede: boolean = false;
  public validaPPT: boolean = false;
  public emissorasTemp: Array<string> = [];
  public emissoras: Emissora[];


  @ViewChild("btnSend") btnLogin: ElementRef;
  @ViewChild("title") titulo: ElementRef;
  @ViewChild("imgDestaque") imgDestaque: ElementRef;
  @ViewChild("focusPdf") pdf: ElementRef;
  @ViewChild("pdfInput") limpaPdf: ElementRef;
  @ViewChild("week") week: ElementRef;
  @ViewChild("url_movie") url: ElementRef;



  constructor(
    private comercialPlainService: ComercialPlainService,
    private alertService: AlertService,
    private http: HttpClient,
    private _router: Router,
    private emissorasService: EmissorasService
  ) {
    this.comercialP = new ComercialPlain();
    this.merchanAdd = new MerchanPlain();
    this.specialActionAdd = new SpecialAction();
  }

  ngOnInit() {
    this.getAll();
    this.getTotal();
    this.getAllEmissoras();
  }

  getAllEmissoras() {
    this.spinTotal = true;
    this.emissorasService.getAll().subscribe(data => {
      this.obj = data;
      this.emissoras = this.obj.result;
      this.spinTotal = false;
      console.log(this.emissoras);
    }, err => {
      console.log(err)
      this.spinTotal = false;
    }, () => {
      this.spinTotal = false;
    });
  }

  FieldsChange(values: any) {
    console.log(values.currentTarget.checked);

    if (values.currentTarget.checked) {
      this.emissorasTemp.push(values.currentTarget.value);

    } else {
      this.emissorasTemp = this.emissorasTemp.filter(
        item => item !== values.currentTarget.value
      );

    }
  }

  verifyCheck(_id) {

    this.emissorasTemp.push(this.comercialP.emissoras.find(x => x == _id));

    return this.comercialP.emissoras.find(x => x == _id);
  }

  getAll() {
    this.spinTotal = true
    this.comercialPlainService.getAll().pipe(take(1)).subscribe(data => {
      this.obj = data;
      this.comercialPlain = this.obj.result;
      this.spinTotal = false
    }, err => {
      console.log(err)
    }, () => {
      this.spinTotal = false;
    });
  }

  getTotal() {
    this.comercialPlainService.getTotal().subscribe(data => {
      this.obj = data;
      this.total = this.obj.result;
    });
  }

  getEditoria(id) {
    return Editoria[id];
  }

  getComercialPlainById(id) {
    this.limpaPdf.nativeElement.value = '';
    this.comercialPlainService.getById(id).subscribe(data => {
      this.obj = data;
      this.comercialP = this.obj.result;
      this._id = this.obj.result._id;

      this.merchanPlain = this.comercialP.comercialFormatMerchan;
      this.specialAction = this.comercialP.specialAction;
      this.btnLogin.nativeElement.textContent = 'Salvar alterações'
    });
  }

  onSearchChange(searchValue: string) {
    this.comercialPlainService.search(searchValue).subscribe(data => {
      this.obj = data;
      this.comercialPlain = this.obj.result;
    });
  }

  delete(id, title) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${title}?`, "OK", "Não")
      .then(willDelete => {
        if (willDelete.value != undefined) {
          this.comercialPlainService.delete(id).subscribe(() => {
            this.getAll();
            this.getTotal();
            this.alertService.success(
              "",
              `${title} deletado com sucesso!`,
              "OK"
            );
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  sendComercialPlain(
    editoria,
    title,
    url_movie,
    description,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    order,
    promo
  ) {
    this.comercialP.editoria = editoria;
    this.comercialP.title = title;
    this.comercialP.url_movie = url_movie;
    this.comercialP.description = description;
    this.comercialP.sunday = sunday;
    this.comercialP.monday = monday;
    this.comercialP.tuesday = tuesday;
    this.comercialP.wednesday = wednesday;
    this.comercialP.thursday = thursday;
    this.comercialP.friday = friday;
    this.comercialP.saturday = saturday;
    this.comercialP.comercialFormatMerchan = this.merchanPlain;
    this.comercialP.specialAction = this.specialAction;
    this.comercialP.order = order;
    this.comercialP.emissoras = this.emissorasTemp;
    this.comercialP.promo = promo;

    this._bloqueiaBotao(true);
    if (this._id != undefined && this._id != "") {
      this.update();
    } else {
      this.save();
    }
  }

  update() {

    var validate = true;
    this.validaTitle = false;
    this.validaUrlVideo = false;

    if (this.comercialP.title == "" || this.comercialP.title == undefined) {
      validate = false;
      this.validaTitle = true;
    }

    if (this.comercialP.url_movie) {
      if (this.comercialP.url_movie.includes('//mais.uol.com.br/view/')) {
        validate = true;
      }
      if (!this.comercialP.url_movie.includes('//mais.uol.com.br/view/')) {
        this.validaUrlVideo = true;
        validate = false
        this.url.nativeElement.focus();
      }
    }

    if (validate) {
      this.comercialPlainService
        .update(this._id, this.comercialP)
        .subscribe(
          this.cbSaveSuccess.bind(this),
          this.cbHandlerError.bind(this),

        );

        this.clearform();
    } else {
      this._bloqueiaBotao(false);
    }


  }

  save() {

    var validate = true;

    this.validaTitle = false;
    this.validaUrlVideo = false;
    this.validaImgDestaque = false;
    this.validaDiasSemana = false;
    this.validaPlano = false;
    this.validaExcel = false;
    this.validaExcelRede = false;
    this.validaPPT = false;

    if (this.image_destaque == undefined ||
      (this.image_destaque != undefined && this.image_destaque.length == 0)
    ) {
      validate = false;
      this.validaImgDestaque = true;
      this.imgDestaque.nativeElement.focus();
    }

    if (this.file_comercialPlain == undefined ||
      (this.file_comercialPlain != undefined && this.file_comercialPlain.length == 0)
    ) {
      validate = false;
      this.validaPlano = true;
      this.pdf.nativeElement.focus();
    }

    if (this.comercialP.title == "" || this.comercialP.title == undefined) {
      validate = false;
      this.validaTitle = true;
      this.titulo.nativeElement.focus();
    }

    if (this.comercialP.url_movie) {
      if (this.comercialP.url_movie.includes('//mais.uol.com.br/view/')) {
        validate = true;
      }
      if (!this.comercialP.url_movie.includes('//mais.uol.com.br/view/')) {
        this.validaUrlVideo = true;
        validate = false
        this.url.nativeElement.focus();
      }
    }

    if (this.comercialP.sunday == false
      && this.comercialP.monday == false
      && this.comercialP.tuesday == false
      && this.comercialP.wednesday == false
      && this.comercialP.thursday == false
      && this.comercialP.friday == false
      && this.comercialP.saturday == false) {
      validate = false;
      this.validaDiasSemana = true;
      this.week.nativeElement.focus();

    }

    if (validate) {

      this.comercialPlainService
        .uploadGeneric(this.image_destaque)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_image_destaque = urlImage;
          this.comercialPlainService
            .uploadGeneric(this.file_comercialPlain)
            .subscribe(data => {
              let url = data.json();
              this.comercialP.url_file_comercialPlain = url;
              this.comercialP.comercialFormatMerchan = this.merchanPlain;
              this.comercialP.specialAction = this.specialAction;


              this.comercialPlainService
                .uploadGeneric(this.url_file_excel_temp)
                .subscribe(data => {
                  let url = data.json();
                  this.comercialP.url_file_excel = url;

                  this.comercialPlainService
                    .uploadGeneric(this.url_file_excel_rede_temp)
                    .subscribe(data => {
                      let url = data.json();
                      this.comercialP.url_file_excel_rede = url;
                      this.comercialPlainService
                        .uploadGeneric(this.url_file_ppt_temp)
                        .subscribe(data => {
                          let url = data.json();
                          this.comercialP.url_file_ppt = url;

                          this.comercialPlainService
                            .save(this.comercialP)
                            .subscribe(
                              this.cbSaveSuccess.bind(this),
                              this.cbHandlerError.bind(this)
                            );

                        });
                    });

                });

            });
        });


    } else {
      this._bloqueiaBotao(false);
    }
  }

  addMerchan(title, url) {

    let m = new MerchanPlain();
    m.title_merchan = title;
    m.url_video_merchan = url;
    this.merchanPlain.push(m);
  }

  addSpecialAction(title, url) {

    let m = new SpecialAction();
    m.title_action = title;
    m.url_video_action = url;
    this.specialAction.push(m);

  }

  /* Init Validate */

  onDeleteItemAction(id, title) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${title}?`, "OK", "Não")
      .then(willDelete => {

        if (willDelete.value != undefined) {

          if (id != undefined && id != "") {
            this.specialAction = this.specialAction.filter(
              item => item._id !== id
            );
          } else {
            this.specialAction = this.specialAction.filter(
              item => item.title_action !== title
            );
          }

        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  onEditItemAction(id, title, url) {
    this.specialActionAdd._id = id;
    this.specialActionAdd.title_action = title;
    this.specialActionAdd.url_video_action = url;

    console.log(this.specialActionAdd);

    this.specialAction = this.specialAction.filter(
      item => item._id !== id
    );

  }

  /* End validate*/


  onDeleteItem(id, title) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${title}?`, "OK", "Não")
      .then(willDelete => {

        if (willDelete.value != undefined) {

          if (id != undefined && id != "") {
            this.merchanPlain = this.merchanPlain.filter(
              item => item._id !== id
            );
          } else {
            this.merchanPlain = this.merchanPlain.filter(
              item => item.title_merchan !== title
            );
          }

        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  onEditItem(id, title, url) {
    this.merchanAdd._id = id;
    this.merchanAdd.title_merchan = title;
    this.merchanAdd.url_video_merchan = url;

    this.merchanPlain = this.merchanPlain.filter(
      item => item._id !== id
    );

  }

  fileChange(event) {
    this.image_destaque = event.target.files;

    if (this.comercialP.url_image_destaque != undefined) {
      this.spin = false
      this.comercialPlainService
        .uploadGeneric(this.image_destaque)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_image_destaque = urlImage;
          this.spin = true
        })
    }

  }

  fileChangePDF(event) {
    this.file_comercialPlain = event.target.files;

    if (this.comercialP.url_file_comercialPlain != undefined) {
      this.spinPdf = false
      this.comercialPlainService
        .uploadGeneric(this.file_comercialPlain)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_file_comercialPlain = urlImage;
          this.spinPdf = true
        })
    }
  }

  fileChangeFileExcel(event) {
    this.url_file_excel_temp = event.target.files;

    if (this.comercialP.url_file_excel != undefined) {
      this.spinExcel = false
      this.comercialPlainService
        .uploadGeneric(this.url_file_excel_temp)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_file_excel = urlImage;
          this.spinExcel = true
        })
    }
  }

  excludUploadExcel() {
    this.comercialPlainService.excludUploads().subscribe((data) => {
      let urlImage = data.json();
      this.comercialP.url_file_excel = urlImage;
      this.alertService.info('', 'Arquivo excluído com sucesso, clique no botão salvar alterações para concluir o processo', 'OK')
    })
  }

  fileChangeFileExcelRede(event) {
    this.url_file_excel_rede_temp = event.target.files;

    if (this.comercialP.url_file_excel_rede != undefined) {
      this.spinExcelRede = false
      this.comercialPlainService
        .uploadGeneric(this.url_file_excel_rede_temp)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_file_excel_rede = urlImage;
          this.spinExcelRede = true
        })
    }
  }

  excludUploadExcelRede() {
    this.comercialPlainService.excludUploads().subscribe((data) => {
      let urlImage = data.json();
      this.comercialP.url_file_excel_rede = urlImage;
      this.alertService.info('', 'Arquivo excluído com sucesso, clique no botão salvar alterações para concluir o processo', 'OK')
    })
  }

  fileChangeUrlFilePpt(event) {
    this.url_file_ppt_temp = event.target.files;

    if (this.comercialP.url_file_ppt != undefined) {
      this.spinPpt = false
      this.comercialPlainService
        .uploadGeneric(this.url_file_ppt_temp)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_file_ppt = urlImage;
          this.spinPpt = true
        })
    }
  }

  excludUploadFilePpt() {
    this.comercialPlainService.excludUploads().subscribe((data) => {
      let urlImage = data.json();
      this.comercialP.url_file_ppt = urlImage;
      this.alertService.info('', 'Arquivo excluído com sucesso, clique no botão salvar alterações para concluir o processo', 'OK')
    })
  }


  addNewPlain() {
    this.clearform();
  }

  cbSaveSuccess(response) {
    this.getAll();
    this.getTotal();
    this.clearform();
    return this.alertService.success("", "Plano enviado com sucesso", "OK");
  }

  cbHandlerError(error) {
    return this.alertService.error(
      "Erro",
      "Ocorreu um erro ao cadastrar o Plano",
      "OK"
    );
  }

  upHandlerError() {
    return this.alertService.error('Erro', 'Ocorreu um erro ao fazer upload de arquivo', 'OK')
  }

  clearform() {
    this._bloqueiaBotao(false);
    this._id = '';
    this.comercialP = new ComercialPlain();
    this.merchanPlain = new Array<MerchanPlain>();
    this.merchanAdd.title_merchan = " ";
    this.merchanAdd.url_video_merchan = " ";


    this.specialAction = new Array<SpecialAction>();
    this.specialActionAdd.title_action = "";
    this.specialActionAdd.url_video_action = "";

    this.btnLogin.nativeElement.textContent = 'Publicar novo plano comercial';

  }


  _bloqueiaBotao(status) {
    const btn = this.btnLogin.nativeElement;
    status
      ? btn.setAttribute("disabled", "disabled")
      : btn.removeAttribute("disabled");
  }

  validateNational(emissoras) {
    return emissoras.indexOf("5cf7b84be49510d57016c719") != -1 ? "NET" : "Praças";
  }


}
