import { Component, OnInit } from '@angular/core';

import { RegionalPlain } from 'src/app/models/regionalPlain.model';

import { RegionalPlainService } from 'src/app/services/regional-plain.service';
import { AlertService } from './../../shared/services/alert.service';

@Component({
  selector: 'app-planos-regionais',
  templateUrl: './planos-regionais.component.html',
  styleUrls: ['./planos-regionais.component.scss']
})
export class PlanosRegionaisComponent implements OnInit {

  public regionalPlain: RegionalPlain = new RegionalPlain();
  public reloadList: string;
  public planos: RegionalPlain[] = []
  public total: number
  public spin:boolean = false;

  public ad = localStorage.getItem('role');
  public permission: boolean = false;

  constructor(
    private regionaPlainService: RegionalPlainService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.verificaAd();
    this.getPlanosRegionais();   
  }

  verificaAd() {
    if(this.ad === 'App_Comercial_Rede' || this.ad === 'APP_Comercial_SP' ){
      this.permission = true;
    }
  }

  getPlanosRegionais() {
    this.spin = true;
    this.regionaPlainService.getPlanoRegional().subscribe(data => {
      this.planos = data.result;
      this.total = this.planos.length;
    },
    ()=>{
      this.alertService.error('','Ocoreu um erro ao carregar a lista de planos', 'ok')
    },() =>{
      this.spin = false;
    });
  }

  getById(id:string){
    this.regionaPlainService.getPlanoRegionalById(id).subscribe(data => {
      this.regionalPlain = data.result;
    })
  }

  delete(event:string) {
    event === 'Deletado' ? this.getPlanosRegionais() : false;
  }

  reload(event:string) {
    event === 'Reload' ? this.getPlanosRegionais() : false;
  }

}
