import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Editoria } from "src/app/models/enum/editoria";
import { SpecialProjects } from "src/app/models/specialProjects";
import { SpecialProjectsService } from "src/app/services/special-projects.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { MerchanProjects } from "src/app/models/merchanProjects";
import { EmissorasService } from "src/app/services/emissoras.service";
import { Emissora } from "src/app/models/emissoras";
import { map, tap, filter, distinctUntilChanged, debounceTime } from "rxjs/operators";

@Component({
  selector: "app-projetos",
  templateUrl: "./projetos.component.html",
  styleUrls: ["./projetos.component.scss"]
})
export class ProjetosComponent implements OnInit {
  _editoriaTipo = Editoria;
  obj: any;
  _id: any;
  key: any;
  selectedValue: Editoria.Arte1;
  selectedFile = null;
  public specialProjects: SpecialProjects[];
  public emissoras: Emissora[];
  public specialP: SpecialProjects;
  public updateSpecialProject: SpecialProjects;
  public editorias: Editoria;
  public total: number;

  public spinTotal: boolean = false;
  public spin: boolean = true;
  public spinPdf: boolean = true;
  public spinExcel: boolean = true;
  public spinExcelRede: boolean = true;
  public spinPpt: boolean = true;



  public merchanProject: Array<MerchanProjects> = [];
  public emissorasTemp: Array<string> = [];


  public merchanAdd: MerchanProjects;
  public urlNull: string = 'https://imagem.band.com.br/novahome/comercial/'

  public image_destaque?: FileList;
  public file_specialProjects?: FileList;

  public url_file_excel_temp?: FileList;
  public url_file_excel_rede_temp?: FileList;
  public url_file_ppt_temp?: FileList;


  public validaTitle: boolean = false;
  public validaUrlVideo: boolean = false;

  public validaImgDestaque: boolean = false;
  public validaPlano: boolean = false;
  public validaExcel: boolean = false;
  public validaExcelRede: boolean = false;
  public validaPPT: boolean = false;


  @ViewChild("btnSend") btnLogin: ElementRef;
  @ViewChild("title") titulo: ElementRef;
  @ViewChild("imgDestaque") imgDestaque: ElementRef;
  @ViewChild("focusPdf") pdf: ElementRef;
  @ViewChild("pdfInput") limpaPdf: ElementRef;
  @ViewChild("url_movie") url: ElementRef;


  constructor(
    private specialProjectsService: SpecialProjectsService,
    private alertService: AlertService,
    private emissorasService: EmissorasService
  ) {
    this.specialP = new SpecialProjects();
    this.merchanAdd = new MerchanProjects();
  }

  ngOnInit() {
    this.getAll();
    this.getTotal();
    this.getAllEmissoras();
  }

  getAllEmissoras() {
    this.spinTotal = true;
    this.emissorasService.getAll().subscribe(data => {
      this.obj = data;
      this.emissoras = this.obj.result;
      this.spinTotal = false;
      console.log(this.emissoras);
    }, err => {
      console.log(err)
      this.spinTotal = false;
    }, () => {
      this.spinTotal = false;
    });
  }

  FieldsChange(values: any) {


    if (values.currentTarget.checked) {
      this.emissorasTemp.push(values.currentTarget.value)
    } else {
      this.emissorasTemp = this.emissorasTemp.filter(
        item => item !== values.currentTarget.value
      );
    }
  }

  verifyCheck(_id) {
    this.emissorasTemp.push(this.specialP.emissoras.find(x => x == _id));

    return this.specialP.emissoras.find(x => x == _id);
  }


  getAll() {
    this.spinTotal = true;
    this.specialProjectsService.getAll().subscribe(data => {
      this.obj = data;
      this.specialProjects = this.obj.result;
      this.spinTotal = false;
    }, err => {
      console.log(err)
      this.spinTotal = false;
    }, () => {
      this.spinTotal = false;
    });
  }

  getTotal() {
    this.specialProjectsService.getTotal().subscribe(data => {
      this.obj = data;
      this.total = this.obj.result;
    });
  }

  getEditoria(id) {
    return Editoria[id];
  }

  getSpecialProjectById(id) {
    this.clearform()
    this.specialP = new SpecialProjects();

    var inputs = document.querySelectorAll('.c-choice');
    for (var i = 0; i < inputs.length; i++) {
    //  inputs[i].checked = false;
    }

    this.specialProjectsService.getById(id).subscribe(data => {
      this.obj = data;
      this.specialP = this.obj.result;
      this.updateSpecialProject = this.specialP
      this._id = this.obj.result._id;
      this.merchanProject = this.specialP.comercialFormatMerchan;
      this.btnLogin.nativeElement.textContent = 'Salvar alterações';
    });
  }

  onSearchChange(searchValue: string) {
    this.specialProjectsService.search(searchValue)
    .subscribe(data => {
      this.obj = data;
      this.specialProjects = this.obj.result;
    });
  }

  delete(id, title) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${title}?`, "OK", "Não")
      .then(willDelete => {
        if (willDelete.value != undefined) {
          this.specialProjectsService.delete(id).subscribe(() => {
            this.getAll();
            this.getTotal();
            this.alertService.success(
              "",
              `${title} deletado com sucesso!`,
              "OK"
            );
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  sendSpecialProjects(editoria, title, url_movie, projectDate, description, order) {
    this.specialP = new SpecialProjects();
    this.specialP.editoria = editoria;
    this.specialP.title = title;
    this.specialP.url_movie = url_movie;
    this.specialP.projectDate = projectDate;
    this.specialP.description = description;
    this.specialP.comercialFormatMerchan = this.merchanProject;
    this.specialP.order = order;
    this.specialP.emissoras = this.emissorasTemp;


    if (this._id != undefined && this._id != "") {
      console.log('here to update');
      this.update();
    } else {
      console.log('here to save');
      this.save();
    }
  }

  addMerchan(title, url) {
    let m = new MerchanProjects();
    m.title_merchan = title;
    m.url_video_merchan = url;
    this.merchanProject.push(m);

    this.merchanAdd.title_merchan = " ";
    this.merchanAdd.url_video_merchan = " ";
  }

  onDeleteItem(id, title) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${title}?`, "OK", "Não")
      .then(willDelete => {
        if (willDelete.value != undefined) {
          if (id != undefined && id != "") {
            this.merchanProject = this.merchanProject.filter(
              item => item._id !== id
            );
          } else {
            this.merchanProject = this.merchanProject.filter(
              item => item.title_merchan !== title
            );
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  onEditItem(id, title, url) {
    this.merchanAdd._id = id;
    this.merchanAdd.title_merchan = title;
    this.merchanAdd.url_video_merchan = url;

    this.merchanProject = this.merchanProject.filter(
      item => item._id !== id
    );

  }


  save() {
    this._bloqueiaBotao(true);
    let validate = true;

    this.validaTitle = false;
    this.validaUrlVideo = false;
    this.validaImgDestaque = false;
    this.validaPlano = false;
    this.validaExcel = false;
    this.validaExcelRede = false;
    this.validaPPT = false;


    if (this.image_destaque == undefined ||
      (this.image_destaque != undefined && this.image_destaque.length == 0)
    ) {
      validate = false;
      this.validaImgDestaque = true;
      this.imgDestaque.nativeElement.focus();
    }

    if (this.file_specialProjects == undefined ||
      (this.file_specialProjects != undefined && this.file_specialProjects.length == 0)
    ) {
      validate = false;
      this.validaPlano = true;
      this.pdf.nativeElement.focus()
    }

    if (this.specialP.title == "" || this.specialP.title == undefined) {
      validate = false;
      this.validaTitle = true;
      this.titulo.nativeElement.focus();
    }

    if (this.specialP.url_movie) {
      if (this.specialP.url_movie.includes('//mais.uol.com.br/view/')) {
        validate = true;
      }
      if (!this.specialP.url_movie.includes('//mais.uol.com.br/view/')) {
        this.validaUrlVideo = true;
        validate = false;
        this.url.nativeElement.focus();
      }
    }

    if (validate) {

      this.specialProjectsService
        .uploadGeneric(this.image_destaque)
        .subscribe(data => {
          let urlImage = data.json();
          this.specialP.url_image_destaque = urlImage;
          this.specialProjectsService
            .uploadGeneric(this.file_specialProjects)
            .subscribe(data => {
              let url = data.json();
              this.specialP.url_file_comercialPlain = url;
              this.specialP.comercialFormatMerchan = this.merchanProject;

              this.specialProjectsService
                .uploadGeneric(this.url_file_excel_temp)
                .subscribe(data => {
                  let url = data.json();
                  this.specialP.url_file_excel = url;

                  this.specialProjectsService
                    .uploadGeneric(this.url_file_excel_rede_temp)
                    .subscribe(data => {
                      let url = data.json();
                      this.specialP.url_file_excel_rede = url;
                      this.specialProjectsService
                        .uploadGeneric(this.url_file_ppt_temp)
                        .subscribe(data => {
                          let url = data.json();
                          this.specialP.url_file_ppt = url;

                          this.specialProjectsService
                            .save(this.specialP)
                            .subscribe(
                              this.cbSaveSuccess.bind(this),
                              this.cbHandlerError.bind(this)
                            );

                        });
                    });

                });

            });
        });
    } else {
      this._bloqueiaBotao(false);
    }
  }

  cbSaveSuccess(response) {
    this._bloqueiaBotao(false);
    this.getAll();
    this.getTotal();
    this.clearform();
    this._bloqueiaBotao(false);
    return this.alertService.success("", "Plano enviado com sucesso", "OK");
  }

  clearform() {
    this._bloqueiaBotao(false);
    this._id = '';
    this.limpaPdf.nativeElement.value = '';
    this.specialP = new SpecialProjects();
    this.merchanProject = new Array<MerchanProjects>();
    this.merchanAdd.title_merchan = " ";
    this.merchanAdd.url_video_merchan = " ";
    this.btnLogin.nativeElement.textContent = 'Publicar novo projeto especial';
  }

  addNewPlain() {
    this.clearform();
  }

  cbHandlerError(error) {
    this._bloqueiaBotao(false);
    return this.alertService.error(
      "Erro",
      "Ocorreu um erro ao cadastrar o Plano",
      "OK"
    );
  }

  upHandlerError() {
    return this.alertService.error('Erro', 'Ocorreu um erro ao fazer upload de arquivo', 'OK')
  }

  fileChange(event) {
    this.image_destaque = event.target.files;
    if (this.specialP.url_image_destaque != undefined) {
      this.spin = false;
      this.specialProjectsService
        .uploadGeneric(this.image_destaque)
        .subscribe(data => {
          let urlImage = data.json();
          this.updateSpecialProject.url_image_destaque = urlImage;
          this.specialP.url_image_destaque = urlImage;
          this.spin = true;
        })
    }

  }

  fileChangePDF(event) {
    this.file_specialProjects = event.target.files;
    if (this.specialP.url_file_comercialPlain != undefined) {
      this.spinPdf = false;
      this.specialProjectsService
        .uploadGeneric(this.file_specialProjects)
        .subscribe(data => {
          let urlImage = data.json();
          this.updateSpecialProject.url_file_comercialPlain = urlImage;
          this.specialP.url_file_comercialPlain = urlImage;
          this.spinPdf = true;
        })
    }
  }

  fileChangeFileExcel(event) {
    this.url_file_excel_temp = event.target.files;

    if (this.specialP.url_file_excel != undefined) {
      this.spinExcel = false;
      this.specialProjectsService
        .uploadGeneric(this.url_file_excel_temp)
        .subscribe(data => {
          let urlImage = data.json();
          this.updateSpecialProject.url_file_excel = urlImage;
          this.specialP.url_file_excel = urlImage;
          this.spinExcel = true;
        })
    }
  }

  excludUploadExcel() {
    this.specialProjectsService.excludUploads().subscribe((data) => {
      let urlImage = data.json();
      this.updateSpecialProject.url_file_excel = urlImage;
      this.specialP.url_file_excel = urlImage;
      this.alertService.info('', 'Arquivo excluído com sucesso, clique no botão salvar alterações para concluir o processo', 'OK')
    })
  }

  fileChangeFileExcelRede(event) {
    this.url_file_excel_rede_temp = event.target.files;

    if (this.specialP.url_file_excel_rede != undefined) {
      this.spinExcelRede = false;
      this.specialProjectsService
        .uploadGeneric(this.url_file_excel_rede_temp)
        .subscribe(data => {
          let urlImage = data.json();
          this.updateSpecialProject.url_file_excel_rede = urlImage;
          this.specialP.url_file_excel_rede = urlImage;
          this.spinExcelRede = true;
        })
    }
  }

  excludUploadExcelRede() {
    this.specialProjectsService.excludUploads().subscribe((data) => {
      let urlImage = data.json();
      this.updateSpecialProject.url_file_excel_rede = urlImage;
      this.specialP.url_file_excel_rede = urlImage;
      this.alertService.info('', 'Arquivo excluído com sucesso, clique no botão salvar alterações para concluir o processo', 'OK')
    })
  }

  fileChangeUrlFilePpt(event) {
    this.url_file_ppt_temp = event.target.files;
    if (this.specialP.url_file_ppt != undefined) {
      this.spinPpt = false;
      this.specialProjectsService
        .uploadGeneric(this.url_file_ppt_temp)
        .subscribe(data => {
          let urlImage = data.json();
          this.updateSpecialProject.url_file_ppt = urlImage;
          this.specialP.url_file_ppt = urlImage;
          this.spinPpt = true;
        })
    }
  }

  excludUploadFilePpt() {
    this.specialProjectsService.excludUploads().subscribe((data) => {
      let urlImage = data.json();
      this.updateSpecialProject.url_file_ppt = urlImage;
      this.specialP.url_file_ppt = urlImage;
      this.alertService.info('', 'Arquivo excluído com sucesso, clique no botão salvar alterações para concluir o processo', 'OK')
    })
  }

  _bloqueiaBotao(status) {
    const btn = this.btnLogin.nativeElement;
    status
      ? btn.setAttribute("disabled", "disabled")
      : btn.removeAttribute("disabled");
  }





  update() {
    this._bloqueiaBotao(true);
    let validate = true;
    this.validaTitle = false;
    this.validaUrlVideo = false;
    this.validaImgDestaque = false;
    this.validaPlano = false;
    this.validaExcel = false;
    this.validaExcelRede = false;
    this.validaPPT = false;

//if (this.updateSpecialProject.title == "" || this.updateSpecialProject.title == undefined) {
    if (this.specialP.title == "" || this.specialP.title == undefined) {
      validate = false;
      this.validaTitle = true;
      this.titulo.nativeElement.focus();
    }


    if (this.specialP.url_movie) {
      if (this.specialP.url_movie.includes('//mais.uol.com.br/view/')) {
        validate = true;
      }
      if (!this.specialP.url_movie.includes('//mais.uol.com.br/view/')) {
        this.validaUrlVideo = true;
        validate = false
        this.url.nativeElement.focus();
      }
    }

    if (validate) {



      this.specialP.url_file_comercialPlain = this.updateSpecialProject.url_file_comercialPlain;
      this.specialP.url_file_excel = this.updateSpecialProject.url_file_excel;
      this.specialP.url_file_excel_rede = this.updateSpecialProject.url_file_excel_rede;
      this.specialP.url_file_ppt = this.updateSpecialProject.url_file_ppt;
      this.specialP.url_image_destaque = this.updateSpecialProject.url_image_destaque;


      this.specialProjectsService
        .update(this.updateSpecialProject._id, this.specialP)
        .subscribe(
          this.cbSaveSuccess.bind(this),
          this.cbHandlerError.bind(this)
        );

        this.clearform();

    } else {
      this._bloqueiaBotao(false);
    }


  }

}


