import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from "@angular/core";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { AcoesDestaque } from "./../../../models/acoesDestaque.model";
//import { Month } from "src/app/models/enum/month";

import { AlertService } from "src/app/shared/services/alert.service";
import { AcoesService } from "./../../../services/acoes.service";
import { EmissorasService } from "src/app/services/emissoras.service";
import { Emissora } from "src/app/models/emissoras";

@Component({
  selector: "app-acoes-form",
  templateUrl: "./acoes-form.component.html",
  styleUrls: ["./acoes-form.component.scss"]
})
export class AcoesFormComponent implements OnInit, OnChanges {
  @ViewChild("btn") btn: ElementRef;

  @Input() acao: AcoesDestaque
  @Output() reload = new EventEmitter();

  // private month = Month;
  // public selectMonth = [];

  public acoesForm: FormGroup;

  obj: any;

  public emissoras: Emissora[];
  public spinTotal: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private acoesService: AcoesService,
    private emissorasService: EmissorasService
  ) { }

  ngOnInit() {
    this.initForm();
    //this.enumMonth();
    this.getAllEmissoras();
  }

  ngOnChanges() {
    this.initComponent();
    this.verificaBtn();
  }

  initComponent() {
    return this.acao._id ? this.setForm() : this.initForm();
  }



  initForm() {
    this.acoesForm = this.fb.group({
      programName: ["", [Validators.required]],
      programBrand: ["", [Validators.required]],
      description: ["", [Validators.required]],
      category: [0],
      url_video: ["", [Validators.required, Validators.pattern("http(s?):\/\/mais\.uol\.com\.br\/view\/\\w+")]],
    });
  }

  setForm() {
    this.acoesForm.setValue({
      programName: this.acao.programName,
      programBrand: this.acao.programBrand,
      description: this.acao.description,
      url_video: this.acao.url_video,
      category: this.acao.category == undefined ? 0 : this.acao.category
    });
  }

  saveForm() {
    this.acoesService.postAcoesDestaque(this.acoesForm.value).subscribe(
      response => {
        this.pbSucess();
      },
      () => {
        this.alertService.error("", "Ocorreu um erro ao cadastrar ação", "OK");
      },
      () => {
        this.btn.nativeElement.removeAttribute("disabled");
      }
    );
  }

  updateForm() {
    this.acoesService
      .updateAcoesDestaque(this.acao._id, this.acoesForm.value)
      .subscribe(
        response => {
          this.pbSucess()
        },
        () => {
          this.alertService.error(
            "",
            "Ocorreu um erro ao cadastrar ação",
            "OK"
          );
        },
        () => {
          this.btn.nativeElement.removeAttribute("disabled");
        }
      );
  }

  submitForm() {
    this.acao._id ? this.updateForm() : this.saveForm();
  }

  pbSucess() {
    this.btn.nativeElement.textContent = "Publicar nova ação de  destaque";
    this.acao = new AcoesDestaque();
    this.reload.emit("Reload");
    this.initForm();
    return this.alertService.success("", "Ação enviada com sucesso", "OK");
  }

  verificaBtn() {
    this.acao._id
      ? (this.btn.nativeElement.textContent = "Atualizar ação de  destaque")
      : (this.btn.nativeElement.textContent =
        "Publicar nova ação de  destaque");
  }

  getAllEmissoras() {
    this.spinTotal = true;
    this.emissorasService.getAll().subscribe(data => {
      this.obj = data;
      this.emissoras = this.obj.result;
      this.spinTotal = false;
      console.log(this.emissoras);
    }, err => {
      console.log(err)
      this.spinTotal = false;
    }, () => {
      this.spinTotal = false;
    });
  }
}
