import { Component, OnInit } from '@angular/core';

import { AcoesService } from './../../services/acoes.service';
import { AlertService } from 'src/app/shared/services/alert.service';

import { AcoesDestaque } from 'src/app/models/acoesDestaque.model';
@Component({
  selector: 'app-acoes',
  templateUrl: './acoes.component.html',
  styleUrls: ['./acoes.component.scss']
})
export class AcoesComponent implements OnInit {

  public acoes: AcoesDestaque[] = [];
  public spin: boolean = false;
  public acao: AcoesDestaque  = new AcoesDestaque();
  public total: number

  constructor(
    private alertService: AlertService,
    private acoesService: AcoesService
  ) { }

  ngOnInit() {
    this.getAcoesDestaque()
  }

  getAcoesDestaque() {
    this.spin = true;
    this.acoesService.getAcoesDestaque().subscribe(response => {
      this.acoes = response.result;
      this.total = this.acoes.length;
    },
    ()=>{
      this.alertService.error('','Ocoreu um erro ao carregar a lista de ações', 'ok')
    },() =>{
      this.spin = false;
    })
  }

  getById(id:string){
    this.acoesService.getAcoesDestaqueById(id).subscribe(response =>{
      this.acao = response.result;
    })
  }

  novaAcao() {
    this.acao = new AcoesDestaque()
  }

  delete(event:string) {
    event === 'Deletado' ? this.getAcoesDestaque() : false;
  }

  reload(event:string) {
    event === 'Reload' ? this.getAcoesDestaque() : false;
  }

}
