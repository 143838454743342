import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { Http } from "@angular/http";

import { environment } from "./../../environments/environment";

import { AcoesDestaque } from "./../models/acoesDestaque.model";

import { HttpUtilService } from "./../shared/services/http-util.service";


@Injectable({
  providedIn: "root"
})
export class AcoesService {
  private API_URL = `${environment.API_URL}destaquesActions`;

  constructor(
    private http: Http,
    private httpUtil: HttpUtilService,
    private _sanitizer: DomSanitizer
  ) { }

  getAcoesDestaque() {
    return this.http
      .get(this.API_URL)
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  getAcoesDestaqueById(id: string) {
    return this.http
      .get(`${this.API_URL}/${id}`)
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  postAcoesDestaque(form: AcoesDestaque) {
    return this.http
      .post(this.API_URL, form, this.httpUtil.headers())
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  updateAcoesDestaque(id: string, form: AcoesDestaque) {
    return this.http
      .put(`${this.API_URL}/${id}`, form, this.httpUtil.headers())
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  deleteAcoes(id: string) {
    return this.http
      .delete(`${this.API_URL}/${id}`)
      .map(this.httpUtil.extrairDados)
      .catch(this.httpUtil.processarErros);
  }

  getVideo(video: String) {

    video = video.replace('https://mais.uol.com.br/posts/edit/','').replace('https://mais.uol.com.br/view/','');

    return this._sanitizer.bypassSecurityTrustResourceUrl(
      `https://thumb.mais.uol.com.br/${video}-xlarge.jpg`
    );
  }
}
