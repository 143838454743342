import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { TabelaPrecoService } from './../../services/tabela-preco.service';
import { PriceTable } from "src/app/models/priceTable";
import { Editoria } from "src/app/models/enum/editoria";
import { AlertService } from "src/app/shared/services/alert.service";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { EmissorasService } from "src/app/services/emissoras.service";
import { Emissora } from "src/app/models/emissoras";

@Component({
  selector: 'app-tabela-preco',
  templateUrl: './tabela-preco.component.html',
  styleUrls: ['./tabela-preco.component.scss']
})
export class TabelaPrecoComponent implements OnInit {
  _editoriaTipo = Editoria;
  obj: any;
  _id: any;
  key: any;
  selectedValue: Editoria.Arte1;
  selectedFile = null;
  public comercialPlain: PriceTable[];
  public comercialP: PriceTable;
  public editorias: Editoria;
  public total: number;
  public urlNull: string = 'https://imagem.band.com.br/novahome/comercial/'

  public spinTotal: boolean = false;
  public spin: boolean = true;
  public spinPdf: boolean = true;

  public image_destaque?: FileList;
  public file_comercialPlain?: FileList;

  public validaTitle: boolean = false;

  public validaImgDestaque: boolean = false;
  public validaPlano: boolean = false;
  public emissoras: Emissora[];


  @ViewChild("btnSend") btnLogin: ElementRef;
  @ViewChild("title") titulo: ElementRef;
  @ViewChild("imgDestaque") imgDestaque: ElementRef;
  @ViewChild("focusPdf") pdf: ElementRef;
  @ViewChild("pdfInput") limpaPdf: ElementRef;

  constructor(
    private tabelaPrecoService: TabelaPrecoService,
    private alertService: AlertService,
    private http: HttpClient,
    private _router: Router,
    private emissorasService: EmissorasService
  ) {
    this.comercialP = new PriceTable();
  }

  ngOnInit() {
    this.getAll();
    this.getTotal();
    this.getAllEmissoras();
  }

  getAllEmissoras() {
    this.spinTotal = true;
    this.emissorasService.getAll().subscribe(data => {
      this.obj = data;
      this.emissoras = this.obj.result;
      this.spinTotal = false;
      console.log(this.emissoras);
    }, err => {
      console.log(err)
      this.spinTotal = false;
    }, () => {
      this.spinTotal = false;
    });
  }

  getAll() {
    this.spinTotal = true;
    this.tabelaPrecoService.getAll().pipe(take(1)).subscribe(data => {
      this.obj = data;
      this.comercialPlain = this.obj.result;
      this.spinTotal = false;
    }, err => {
      console.log(err);
    }, () => {
      this.spinTotal = false;
    });
  }

  getTotal() {
    this.tabelaPrecoService.getTotal().subscribe(data => {
      this.obj = data;
      this.total = this.obj.result;
    });
  }

  getEditoria(id) {
    return Editoria[id];
  }

  getComercialPlainById(id) {
    this.limpaPdf.nativeElement.value = '';
    this.tabelaPrecoService.getById(id).subscribe(data => {
      this.obj = data;
      this.comercialP = this.obj.result;
      this._id = this.obj.result._id;

      this.btnLogin.nativeElement.textContent = 'Salvar alterações'
    });
  }

  onSearchChange(searchValue: string) {
    this.tabelaPrecoService.search(searchValue).subscribe(data => {
      this.obj = data;
      this.comercialPlain = this.obj.result;
    });
  }

  delete(id, title) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${title}?`, "OK", "Não")
      .then(willDelete => {
        if (willDelete.value != undefined) {
          this.tabelaPrecoService.delete(id).subscribe(() => {
            this.getAll();
            this.getTotal();
            this.alertService.success(
              "",
              `${title} deletado com sucesso!`,
              "OK"
            );
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  sendComercialPlain(
    editoria,
    title,
  ) {
    this.comercialP.editoria = editoria;
    this.comercialP.title = title;

    this._bloqueiaBotao(true);
    if (this._id != undefined && this._id != "") {
      this.update();
    } else {
      this.save();
    }
  }

  update() {

    var validate = true;
    this.validaTitle = false;

    if (this.comercialP.title == "" || this.comercialP.title == undefined) {
      validate = false;
      this.validaTitle = true;
    }

    if (validate) {
      this.tabelaPrecoService
        .update(this._id, this.comercialP)
        .subscribe(
          this.cbSaveSuccess.bind(this),
          this.cbHandlerError.bind(this),

        );

        this.clearform();
    } else {
      this._bloqueiaBotao(false);
    }


  }

  save() {

    var validate = true;

    this.validaTitle = false;
    this.validaImgDestaque = false;
    this.validaPlano = false;

    if (this.image_destaque == undefined ||
      (this.image_destaque != undefined && this.image_destaque.length == 0)
    ) {
      validate = false;
      this.validaImgDestaque = true;
      this.imgDestaque.nativeElement.focus();
    }

    if (this.file_comercialPlain == undefined ||
      (this.file_comercialPlain != undefined && this.file_comercialPlain.length == 0)
    ) {
      validate = false;
      this.validaPlano = true;
      this.pdf.nativeElement.focus();
    }

    if (this.comercialP.title == "" || this.comercialP.title == undefined) {
      validate = false;
      this.validaTitle = true;
      this.titulo.nativeElement.focus();
    }

    if (validate) {

      this.tabelaPrecoService
        .uploadGeneric(this.image_destaque)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_image_destaque = urlImage;
          this.tabelaPrecoService
            .uploadGeneric(this.file_comercialPlain)
            .subscribe(data => {
              let url = data.json();
              this.comercialP.url_file_comercialPlain = url;

            });
        });


    } else {
      this._bloqueiaBotao(false);
    }
  }

  fileChange(event) {
    this.image_destaque = event.target.files;

    if (this.comercialP.url_image_destaque != undefined) {
      this.spin = false;
      this.tabelaPrecoService
        .uploadGeneric(this.image_destaque)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_image_destaque = urlImage;
          this.spin = true;
        })
    }

  }

  fileChangePDF(event) {
    this.file_comercialPlain = event.target.files;

    if (this.comercialP.url_file_comercialPlain != undefined) {
      this.spinPdf = false;
      this.tabelaPrecoService
        .uploadGeneric(this.file_comercialPlain)
        .subscribe(data => {
          let urlImage = data.json();
          this.comercialP.url_file_comercialPlain = urlImage;
          this.spinPdf = true;
        })
    }
  }


  addNewPlain() {
    this.clearform();
  }

  cbSaveSuccess(response) {
    this.getAll();
    this.getTotal();
    this.clearform();
    return this.alertService.success("", "Tabela enviada com sucesso", "OK");
  }

  cbHandlerError(error) {
    return this.alertService.error(
      "Erro",
      "Ocorreu um erro ao cadastrar a Tabela",
      "OK"
    );
  }

  upHandlerError() {
    return this.alertService.error('Erro', 'Ocorreu um erro ao fazer upload de arquivo', 'OK')
  }

  clearform() {
    this._bloqueiaBotao(false);
    this._id = '';
    this.comercialP = new PriceTable();

    this.btnLogin.nativeElement.textContent = 'Publicar nova tabela de preços';

  }


  _bloqueiaBotao(status) {
    const btn = this.btnLogin.nativeElement;
    status
      ? btn.setAttribute("disabled", "disabled")
      : btn.removeAttribute("disabled");
  }


}
