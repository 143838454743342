import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from "@angular/core";

import { AcoesDestaque } from './../../../models/acoesDestaque.model';

import { AlertService } from 'src/app/shared/services/alert.service';
import { AcoesService } from './../../../services/acoes.service';

@Component({
  selector: 'app-acoes-list',
  templateUrl: './acoes-list.component.html',
  styleUrls: ['./acoes-list.component.scss']
})
export class AcoesListComponent implements OnInit, OnChanges {

  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();

  @Input() acoes: AcoesDestaque[] = [];
  @Input() spin: boolean

  public filtro: any = { programName: '' };

  constructor(
    private alertService: AlertService,
    public acoesService: AcoesService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
  }


  updateAcoesDestaque(id: string) {
    this.update.emit(id);
  }

  deleteAcaoDestaque(id: string, programName: string) {
    this.alertService
      .question("", `Deseja realmente deletar esse ${programName}?`, "OK", "Não")
      .then(willDelete => {
        if (willDelete.value) {
          this.acoesService.deleteAcoes(id).subscribe(() => {
            this.alertService.success("", "Título deletado com sucesso", "OK");
            this.delete.emit('Deletado');
          });
        }
      });
  }

  getCategory(value) {
    let category = "";
    switch (value) {
      case 1:
          category = "Bandnews";
        break;
      case 2:
          category = "Bandsports";
        break;
      case 3:
          category = "Terraviva";
        break;
      case 4:
          category = "Arte1";
        break;
      case 5:
          category = "Sexprivê";
        break;
      case 6:
          category = "Smithsonian Channel";
        break;
    }

    return category;
  }


}
