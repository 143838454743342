export enum Editoria {
  Bandnews = 1,
  Bandsports = 2,
  Terraviva = 3,
  Arte1 = 4,
  Sexprivé = 5,
  "Smithsonian Channel" = 6
}


/*export enum Editoria {
    Entretenimento = 1,
    Noticia = 2,
    Esportes = 3
}*/
