import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { Estado } from "src/app/models/enum/estado";
import { Category } from "./../../../models/enum/category";
import { Status } from "src/app/models/enum/status";

import { RegionalPlain } from "src/app/models/regionalPlain.model";

import { RegionalPlainService } from "src/app/services/regional-plain.service";
import { AlertService } from "src/app/shared/services/alert.service";



@Component({
  selector: "app-plano-form",
  templateUrl: "./plano-form.component.html",
  styleUrls: ["./plano-form.component.scss"]
})
export class PlanoFormComponent implements OnInit, OnChanges {
  @ViewChild("btn") btn: ElementRef;
  @ViewChild("imgInput") upImg: ElementRef;
  @ViewChild("planoRegional") upPdf: ElementRef;

  @Input() regionalPlain: RegionalPlain;  
  @Output() reload = new EventEmitter();
  @Input() ad: boolean = false;

  private category = Category;
  private estado = Estado;
  private status = Status 

  public selectCategory = [];
  public selectEstado = []; 
  public selectStatus = [];

  public spin: boolean = false;
  public spinPdf: boolean = false;
  public spinImg: boolean = false;

  public url_imagem: any;
  public planoFile: any;
  public planosForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private regionaPlainService: RegionalPlainService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.enumCategory();
    this.enumEstado(); 
    this.enumStatus();   
  }

  ngOnChanges() {
    this.initComponent();
    this.verificaBtn();
  }

  initComponent() {
    return this.regionalPlain._id ? this.setform() : this.initForm();
  }

  initForm() {    
    this.planosForm = this.fb.group({
      title: ["", [Validators.required]],
      category: [1, [Validators.required]],
      url_imagem: ["", [Validators.required]],
      url_movie: [
        "",
        [Validators.pattern("http(s?):\/\/mais\.uol\.com\.br\/view\/\\w+")]
      ],
      state: [1, [Validators.required]],
      emissora_name: ["", [Validators.required]],
      url_file_comercialPlain: ["", [Validators.required]],
      description: ["", [Validators.required]],
      order: [""],
      status:[ this.ad ? 1 : 4 ]
    });
  }

  setform() {
    this.planosForm.setValue({
      title: this.regionalPlain.title,
      category: this.regionalPlain.category,
      url_imagem: this.regionalPlain.url_imagem,
      url_movie: this.regionalPlain.url_movie,
      state: this.regionalPlain.state,
      emissora_name: this.regionalPlain.emissora_name,
      url_file_comercialPlain: this.regionalPlain.url_file_comercialPlain,
      description: this.regionalPlain.description,
      order: this.regionalPlain.order,
      status: this.regionalPlain.status ? this.regionalPlain.status : 1
    });
  }

  enumCategory() {
    for (const key in this.category) {
      typeof this.category[key] === "string"
        ? this.selectCategory.push(this.category[key])
        : false;
    }
  }

  enumStatus() {
    for (const key in this.status) {
        this.status[key] === "Active" || this.status[key] === "Inactive"
        ? this.selectStatus.push(this.status[key])
        : false;
    }
  }

  enumEstado() {
    for (const key in this.estado) {
      typeof this.estado[key] === "string"
        ? this.selectEstado.push(this.estado[key])
        : false;
    }
  }
 

  fileChange(event: any) {
    const url_imagem = this.planosForm.get("url_imagem").value;
    event ? this.url_imagem = <File>event.target.files[0] : this.url_imagem = '';
    if (url_imagem) {
      this.spinImg = true;
      this.regionaPlainService.uploadImage(this.url_imagem).subscribe(
        data => {
          let urlImage = data.json();
          this.planosForm.controls["url_imagem"].patchValue(urlImage);
        },
        () => {
          this.upHandlerError();
        },
        () => {
          this.spinImg = false;
        }
      );
    }
  }

  planoChange(event: any) {
    const plano = this.planosForm.get("url_file_comercialPlain").value;
    event ? this.planoFile = <File>event.target.files[0] : this.planoFile = '' ;   
    if (plano) {
      this.spinPdf = true;
      this.regionaPlainService.uploadPdf(this.planoFile).subscribe(
        (data) => {          
          let urlPadf = data.json();
          this.planosForm.controls["url_file_comercialPlain"].patchValue(urlPadf);
        },
        () => {
          this.upHandlerError();
        },
        () => {
          this.spinPdf = false;
        }
      );
    }
  }

  saveForm() {
    if (this.url_imagem && this.planoFile){
      this.btn.nativeElement.setAttribute("disabled", "disabled");
     return this.regionaPlainService
      .fileUploadRegionalForms(this.url_imagem, this.planoFile)
      .subscribe(data => {
        let urlImg = data[0]._body.split('"')[1];
        let urlPdf = data[1].json();
        this.planosForm.controls["url_imagem"].patchValue(urlImg);
        this.planosForm.controls["url_file_comercialPlain"].patchValue(urlPdf);
        this.regionaPlainService
          .postPlanoRegional(this.planosForm.value)
          .subscribe(
            data => {
              this.pbSucess();
            },
            () => {
              return this.alertService.error(
                "Erro",
                "Ocorreu um erro ao cadastrar o Plano",
                "OK"
              );
            },
            () => {
              this.btn.nativeElement.removeAttribute("disabled");
            }
          );
      });
    }

    return this.alertService.error('Erro', 'Você precisa selecionar uma imagem e um plano  para salvar', 'OK');
      
  }

  updateForm() {
    this.btn.nativeElement.setAttribute("disabled", "disabled");
    this.regionaPlainService
      .updatePlanoRegional(this.regionalPlain._id, this.planosForm.value)
      .subscribe(
        data => {
          this.pbSucess();
        },
        () => {
          return this.alertService.error(
            "Erro",
            "Ocorreu um erro ao atualizar o Plano",
            "OK"
          );
        },
        () => {
          this.btn.nativeElement.removeAttribute("disabled");
        }
      );
  }

  pbSucess() {
    this.btn.nativeElement.textContent = "Publicar novo plano comercial";
    this.regionalPlain = new RegionalPlain();
    this.reload.emit("Reload");
    this.upImg.nativeElement.value = "";
    this.upPdf.nativeElement.value = "";
    this.initForm();
    return this.alertService.success("", "Plano enviado com sucesso", "OK");
  }

  upHandlerError() {
    return this.alertService.error(
      "Erro",
      "Ocorreu um erro ao fazer upload de arquivo",
      "OK"
    );
  }

  bloqueiaBotao() {
    const btn = this.btn.nativeElement;
    this.spin
      ? btn.setAttribute("disabled", "disabled")
      : btn.removeAttribute("disabled");
  }

  verificaBtn() {
    this.regionalPlain._id
      ? (this.btn.nativeElement.textContent = "Atualizar plano comercial")
      : (this.btn.nativeElement.textContent = "Publicar novo plano comercial");
  }

  validateDownload() {
    return this.planosForm.get('url_file_comercialPlain').value.match(/http(s?):\/\/imagem\.band\.com\.br\/novahome\/comercial\/\w+.+/g)
  }

  submitForm() {
    this.regionalPlain._id ? this.updateForm() : this.saveForm();
  }
}
